<template>
  <div class="sfjaisle">
    <h2>这是水肥机配置页面</h2>
  </div>
</template>
<script>
export default {
  name: 'Sfjaisle',
  props: {
    farmId: {
      type: Number,
      default: 0
    },
    sceneId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      listCol: [],
      listData: [],
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10
      },
      filter: {},
      listLoading: false,

    }
  },
  methods: {

  },
  mounted () {
    this.filter = {
      farmId: this.farmId,
      sceneId: this.sceneId,
    }

  }
}
</script>
<style lang="less">

</style>


