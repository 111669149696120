<template>
<div class = "sceneCfgMonitor">
  <!-- <div class = "filterItem farmScene">
    <Cascader
      :value="farmScene"
      :data="farmOptions" 
      :load-data="getChildren" 
      trigger="click"  
      style="min-width: 300px;"
      placeholder="选择基地/场景"
      placement="top"
      :clearable="false"
      @on-change="farmSceneChange"
    />
  </div> -->
  <Tabs @on-click="delStatusChange" :value="currrentTab">
    <TabPane v-if="accessData.sceneSensor" label="监测" icon="ios-pulse" name = "monitor">
      <!-- <Smonitor
        :farmId="farmCurrentId"
        :sceneId="sceneCurrentId"
      /> -->
      <keep-alive>
        <smonitorcomponent 
          :is="tabComponent.sm"
          :farmId="farmCurrentId"
          :sceneId="sceneCurrentId"
        />
      </keep-alive>
    </TabPane>
    <TabPane v-if = "accessData.sceneCtr" label="控制" icon="ios-speedometer" name = "control">
      <!-- <Scontrol
        :farmId="farmCurrentId"
        :sceneId="sceneCurrentId"
      /> -->
      <keep-alive>
        <scontrolcomponent 
          :is="tabComponent.sc"
          :farmId="farmCurrentId"
          :sceneId="sceneCurrentId"
        />
      </keep-alive>
    </TabPane>
    <!-- <TabPane :label="sfjaisle" name = "sfjaisle">
        <sfjcomponent
          :is="tabComponent.sfj"
          :farmId="farmCurrentId"
          :sceneId="sceneCurrentId"
        />
    </TabPane> -->
    <!-- <TabPane v-if = "accessData.sceneVideo === 1" label="视频" icon="ios-film" name = "video">
        <svideocomponent
          :is="tabComponent.sv"
          :farmId="farmCurrentId"
          :sceneId="sceneCurrentId"
        />
    </TabPane> -->
  </Tabs>
</div>
</template>
<script>
/*
* 控制表格开关状态、锁定状态此处均为string类型
*/
import Smonitor from './monitor/smonitor.vue'
import Scontrol from './control/scontrol.vue'
import Sfjaisle from './sfjaisle/sfjaisle.vue'
import Svideo from './video/svideo.vue'

export default {
  name: 'sceneCfgMonitor',
  components: {
    Smonitor,
    Scontrol,
    Sfjaisle,
    Svideo,
  },
  data () {
    return {
      currrentTab: 'monitor',
      tabComponent: {
        sm: Smonitor,
        sc: '',
        sfj: '',
        // sv: ''
      },
      /* 标签头渲染数据 */
      monitor: (createEle) => {
        return createEle('div', {
          style: {
            'font-size': '18px'
          }
        }, [
          createEle('Icon', {
            props: {
              'type': 'ios-pulse'
            }
          }),
          '监测'
        ])
      },
      control: (createEle) => {
        return createEle('div', {
          style: {
            'font-size': '18px'
          }
        }, [
          createEle('Icon', {
            props: {
              'type': 'ios-speedometer'
            }
          }),
          '控制'
        ])
      },
      sfjaisle: (createEle) => {
        return createEle('div', {
          style: {
            'font-size': '18px'
          }
        }, [
          createEle('Icon', {
            props: {
              'type': 'ios-bonfire'
            }
          }),
          '水肥机'
        ])
      },
      record: (createEle) => {
        return createEle('div', {
          style: {
            'font-size': '18px'
          }
        }, [
          createEle('Icon', {
            props: {
              'type': 'ios-film'
            }
          }),
          '视频'
        ])
      },
      
      farmOptions: [],
      farmScene: [],
      
      // 监测内容表格已选行数据存储
      
      // 控制内容表格已选行数据存储
      
      // 视频记录内容表格已选行数据存储
      
      // 删除确认框显示切换
      
      // 删除确认框状态 1: 监测，2: 控制，3: 视频
      
      /* 控制内容表格数据************** */
      // 控制表格行编辑临时数据存储
      
      // 控制表格场景类型下拉菜单
      
      // 控制表格控制器类别
      // 控制表格参数查询用当前传感器名称
      
      /* 视频记录表格数据************** */
      // 视频记录表格行编辑缓存数据
      
      // 视频记录表格字段
      // 设备类型下拉菜单
      
      // 当前农场id
      farmCurrentId: '',
      // 当前场景id
      sceneCurrentId: '',
      // 当前筛选通道名称
      
      // 监测表格参数查询用当前传感器名称
      
      // 监测表格行编辑缓存数据
      
      // 监测表格监测类别下拉列表（传感器类别）
      
      // 监测表格监测类型下拉列表（传感器类型）
      
      // 监测表格通道下拉列表
      
      // 表格已选择删除列表
      
      // 当前选择场景类型
      
    }
  },
  computed: {
    accessData () {
      return {
        // 检测、控制、视频表格权限
        sceneSensor: true, // this.$store.state.app.permissionsGroup.sceneSensor,
        sceneCtr: true, // this.$store.state.app.permissionsGroup.sceneCtr,
        sceneVideo: true, // this.$store.state.app.permissionsGroup.sceneVideo,
      }
    }
  },
  methods: {
    backDevice () {
      this.$router.push({name: 'sceIndex'})
    },

    farmSceneChange (val, selData) {
      this.farmCurrentId = val[0];
      this.sceneCurrentId = val[1];
    },

    getChildren (item, cb) {
      item.loading = true,
      this.$post(this.$api.BASE.BASE_SCENE_LIST, {
        farmId: item.value,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        item.children = resp.list.map(elem => ({
          value: elem.sceneId,
          label: elem.name,
        }));
        item.loading = false;
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },
    
    getFarmList () {
      return this.$post(this.$api.BASE.BASE_LIST, {
        pageNo: 1,
        pageSize: 99999
      }).then(resp => {
        return this.farmOptions = resp.list.map(item => ({
          value: +item.farmId,
          label: item.name,
          children: [],
          loading: false,
        }));
      })
    },

    getSceneList () {
      return this.$post(this.$api.BASE.BASE_SCENE_LIST, {
        farmId: this.farmCurrentId,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        return resp.list.map(el => el)
      }).catch(e => {
        
      })
    },
    
    filterChange (field, type, val, selData) {
      if (type === 'cas') {
        this.farmId = val[0];
        this.sceneId = val[1];
        this.videoId = val[2];
        this.getVideoDetail(this.videoId);
        this.getList(true, true);
      } else if (type === 'input') {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter = {
            ...this.filter,
            [field]: val.target.value ? val.target.value : void 0,
          };
          this.getList(true)
        }, 500);
      } else if (type === 'select') {
        this.filter = {
            ...this.filter,
            [field]: val ? val.value : val,
        }
        this.getList(true);
      } else if (type === 'datetimerange') {
        this.filter={
          ...this.filter,
          beginTime: val[0] ? val[0] : void 0,
          endTime: val[1] ? val[1] : void 0,
        }
        this.getList(true);
      }
    },


    // 获取传感器(监测)分组列表
    
    // 获取控制器分组列表
    
    // 获取视频记录分组列表
    
    // 获取场景类型（传感器类型）
    
    // 获取场景类型（控制类型）
    
    // 获取监测类别
    
    // 获取控制类别
    
    // 获取场景通道列表
    
    // 获取监测类别（传感器类别）
    
    // render函数中select下拉菜单生成函数
    
    // 删除弹窗状态函数(tab点击事件)
    delStatusChange (name) {
      this.delConfirmModelStatus = name
      switch (name) {
        case 'monitor':
          // this.getMonitorList()
          this.tabComponent = {
            ...this.tabComponent,
            sm: Smonitor,
            sc: '',
            sfj: '',
            //sv: '',
          };
          break;
        case 'control':
          // this.getControlList()
          this.tabComponent = {
            ...this.tabComponent,
            sm: '',
            sc: Scontrol,
            sfj: '',
            //sv: '',
          };
          break;
        case 'sfjaisle':
          this.tabComponent = {
            ...this.tabComponent,
            sm: '',
            sc: '',
            sfj: Sfjaisle,
            //sv: '',
          };
          break;
        /* case 'video':
          this.tabComponent = {
            ...this.tabComponent,
            sm: '',
            sc: '',
            sfj: '',
            sv: Svideo,
          };
          break; */
      }
    },
    // 删除确认框list判断函数
    
    // 获取删除list元素种类
    
    // 监测表格行编辑失焦和相关字段选择事件保存数据事件
    
    // 控制表格行编辑失焦和相关字段选择事件保存数据事件
    
    // 控制表格开关状态切换
    
    // 控制表格锁定状态切换
    
    // 视频记录表格行编辑失焦和相关字段选择事件保存数据事件
    
    // 监测表格开启行编辑
    
    // 监测表格行编辑保存
    
    // 控制表格行编辑保存
    
    // 视频记录表格保存行编辑
    // 监测表格取消保存行编辑
    
    // 控制表格取消保存行编辑
    
    // 视频记录表格取消行编辑
    // 控制表格开启行编辑
    
    // 视频记录表格开启行编辑
    // 监测表格删除行
    
    // 控制表格删除行recordRowEdit
    
    // 视频记录表格删除行

    // 获取视频控制设备类型

    // select下拉菜单生成函数, 本函数应有4个参数，后面两个为获取下拉数据的api、下拉菜单数据依赖（若无可省略）
    // 监测表格多选事件
    
    // 控制表格多选事件
    
    // 视频记录表格多选事件
    
    // 监测表格批量删除

    // 确认删除监测表格所选行
    
    // 控制表格批量删除
    
    // 确认删除控制表格所选行
    
    // 视频记录表格批量删除
    // 确认删除控制表格所选行
    
    // 页码切换函数
    
    // 页码切换函数
    
    // 页码切换函数
  },
  beforeMount () {
    this.farmCurrentId = +this.$router.history.current.query.farmId;
    this.sceneCurrentId = +this.$router.history.current.query.sceneId;
  },

  mounted () {
    /* this.getFarmList().then(resp => {
      if (!this.farmCurrentId) {
        this.farmCurrentId = resp[0] ? resp[0].value : void 0;
        this.getSceneList().then(res => {
          if (!this.sceneCurrentId) {
            this.sceneCurrentId = res[0] ? res[0].sceneId : void 0;
          }
          this.farmScene = [this.farmCurrentId, this.sceneCurrentId];
        })
      }
    })
    this.farmCurrentId && (this.farmScene = [this.farmCurrentId, this.sceneCurrentId]); */
    // this.getDeviceTypeList()
  }
}
</script>
<style lang="less">
  @import "./sceneCfgMonitor.less";
</style>


